import { TopNavDocument } from '@dtx-company/prismic-codegen'

export const topNavData: TopNavDocument = {
  id: 'YVSq7RAAACEADfaZ',
  uid: 'marketing_nav',
  url: null,
  type: 'top_nav',
  href: 'https://flowcode-ui.cdn.prismic.io/api/v2/documents/search?ref=ZnR8HREAACUAlhmw&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22YVSq7RAAACEADfaZ%22%29+%5D%5D',
  tags: ['Marketing Nav'],
  first_publication_date: '2021-09-30T20:55:43+0000',
  last_publication_date: '2024-06-13T18:32:54+0000',
  slugs: ['top-nav', 'products', 'nav-dropdown'],
  linked_documents: [],
  lang: 'en-us',
  alternate_languages: [],
  data: {
    contact_us_title: 'Talk to sales',
    contact_us_subtitle: 'Get a personalized demo',
    contactus_description:
      'See the full power of the Flowcode platform, and how it help you grow your business.',
    contact_us_link_text: "Let's chat",
    contact_us_image: {
      dimensions: {
        width: 698,
        height: 400
      },
      alt: 'Talk to sales',
      copyright: null,
      url: '/graphics/locg/Rectangle32.png'
    },
    contact_us_link: {
      link_type: 'Web',
      url: 'https://www.flowcode.com/contact-sales'
    },
    body: [
      {
        primary: {
          header: [
            {
              type: 'heading2',
              text: 'Features',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Any'
          },
          isexternal: false,
          columns: 3,
          isdense: false
        },
        items: [
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Flowcode',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Free QR code generator',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/free-qr-code-generator'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Convert',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Flowpage',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Mobile-optimized landing pages designed to convert customers.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/page'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Convert',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Conversion Flows',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'All-in-one conversion funnel, optimized to collect first party data.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/conversion-flows'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Convert',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Analytics',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Real-time data and ROI metrics on your real world activity.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/analytics'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Report',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'FlowID',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Monitor customer conversion history across offline touchpoints.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/flow-id'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Report',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Flowcode API',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Connect your tech stack to automate your workflows.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/developer-portal'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Report',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Workspaces',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Set team permissions and manage assets for your organization.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/workspaces'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Manage',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Integrations',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Connect Flowcode data to your favorite tools.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/integrations'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Manage',
            badge: null
          }
        ],
        id: 'desktop$4dc071b5-ec18-40e0-9936-20e96243fd6f',
        slice_type: 'desktop',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'heading2',
              text: 'Solutions',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Any'
          },
          isexternal: false,
          columns: 3,
          isdense: true
        },
        items: [
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Media and agencies',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Access omnichannel marketing tools that deliver real-time 1st party data.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/media-agency'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By industry',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Sports',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Create seamless fan activations in-stadium and on-screen.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/sports'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By industry',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Events',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Instantly connect with audiences at scale and collect insights.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/events'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By industry',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Explore all',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'See how more industries are powered by Flowcode.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By industry',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'On TV and CTV',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Optimize your media based on real-time insights.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/tv'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By use case',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Collecting CRM',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Collect, analyze, and utilize customer data.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/use-cases/qr-codes-for-crm'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By use case',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Personalized experiences',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Bridge the gap between physical intent and digital engagement.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/use-cases/qr-codes-for-direct-mail'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By use case',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Explore all',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'See how businesses use Flowcode.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/use-cases'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'By use case',
            badge: null
          }
        ],
        id: 'desktop$6fc8f618-6244-4f00-93cd-6f15b479bce2',
        slice_type: 'desktop',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'heading2',
              text: 'Resources',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Any'
          },
          isexternal: false,
          columns: 3,
          isdense: true
        },
        items: [
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Blog',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Tips and tricks for growing your business.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/blog'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Learn',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Help center',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Support for Flowcode 1, our classic QR code manager.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://help.flowcode.com/en/'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Support',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Enterprise help center',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'Support for Flowcode 2, our enterprise conversion platform.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://help.flowcode.com/enterprise/en/'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Support',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Careers',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: "We're looking for bar-raisers. Ready to join us?",
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/careers'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Connect',
            badge: null
          },
          {
            link_title: [
              {
                type: 'paragraph',
                text: 'Our team',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: 'The team is the product. The product is the team.',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/team'
            },
            fullwidth: false,
            isexternal: false,
            subsection: 'Connect',
            badge: null
          }
        ],
        id: 'desktop$5186ffe8-2a12-4da8-aa9e-c5661959d498',
        slice_type: 'desktop',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'heading2',
              text: 'Pricing',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Web',
            url: 'https://www.flowcode.com/pricing'
          },
          isexternal: false,
          columns: 4,
          isdense: true
        },
        items: [],
        id: 'desktop$badc0fb4-c688-4abe-8606-5697f3f14143',
        slice_type: 'desktop',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'paragraph',
              text: 'Features',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Any'
          },
          isexternal: false
        },
        items: [
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Flowcode',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: '',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/free-qr-code-generator'
            },
            isexternal: false,
            subsection: 'Convert',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Flowpage',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: '',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/page'
            },
            isexternal: false,
            subsection: 'Convert',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Conversion Flows',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: '',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/conversion-flows'
            },
            isexternal: false,
            subsection: 'Convert',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'heading1',
                text: 'Analytics',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/analytics'
            },
            isexternal: false,
            subsection: 'Report',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading1',
                text: 'FlowID',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/features/flow-id'
            },
            isexternal: false,
            subsection: 'Report',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading1',
                text: 'Flowcode API',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/developer-portal'
            },
            isexternal: false,
            subsection: 'Report',
            badge: null
          }
        ],
        id: 'mobile$e1dde7dc-4f91-4312-852e-a824df5f311c',
        slice_type: 'mobile',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'paragraph',
              text: 'Solutions',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Any'
          },
          isexternal: false
        },
        items: [
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Media and agencies',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: '',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/media-agency'
            },
            isexternal: false,
            subsection: 'By Industry',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Sports',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: '',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/sports'
            },
            isexternal: true,
            subsection: 'By Industry',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Events',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/events'
            },
            isexternal: true,
            subsection: 'By Industry',
            badge: 'Enterprise'
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Explore all',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry'
            },
            isexternal: false,
            subsection: 'By Industry',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'On TV and CTV',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/industry/tv'
            },
            isexternal: false,
            subsection: 'By use case',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Collecting CRM',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/use-cases/qr-codes-for-crm'
            },
            isexternal: false,
            subsection: 'By use case',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Personalized experiences',
                spans: []
              }
            ],
            link_subtext: [
              {
                type: 'paragraph',
                text: '',
                spans: []
              }
            ],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/use-cases/qr-codes-for-direct-mail'
            },
            isexternal: true,
            subsection: 'By use case',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Explore all',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/use-cases'
            },
            isexternal: true,
            subsection: 'By use case',
            badge: null
          }
        ],
        id: 'mobile$19a2aee2-3fa6-4fb8-b1d2-f79fae975775',
        slice_type: 'mobile',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'paragraph',
              text: 'Resources',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Any'
          },
          isexternal: false
        },
        items: [
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Blog',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/blog'
            },
            isexternal: false,
            subsection: 'Learn',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Help center',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://help.flowcode.com/en/'
            },
            isexternal: false,
            subsection: 'Support',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Enterprise help center',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://help.flowcode.com/enterprise/en/'
            },
            isexternal: false,
            subsection: 'Support',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Careers',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/careers'
            },
            isexternal: false,
            subsection: 'Connect',
            badge: null
          },
          {
            link_title: [
              {
                type: 'heading2',
                text: 'Our team',
                spans: []
              }
            ],
            link_subtext: [],
            dropdown_link: {
              link_type: 'Web',
              url: 'https://www.flowcode.com/team'
            },
            isexternal: false,
            subsection: 'Connect',
            badge: null
          }
        ],
        id: 'mobile$dc584466-47bc-4af1-8c71-c49c5da04815',
        slice_type: 'mobile',
        slice_label: null
      },
      {
        primary: {
          header: [
            {
              type: 'paragraph',
              text: 'Pricing',
              spans: []
            }
          ],
          header_link: {
            link_type: 'Web',
            url: 'https://www.flowcode.com/pricing'
          },
          isexternal: false
        },
        items: [],
        id: 'mobile$fbfc5fb8-8031-47e7-83f0-d4881f8c78ee',
        slice_type: 'mobile',
        slice_label: null
      }
    ]
  }
}
